import React from 'react'
import Helmet from 'react-helmet'

import Layout from '../../components/layout'
import HeaderGeneric from '../../components/HeaderGeneric'

class Generic extends React.Component {
  render() {

    return (
      <Layout>
        <Helmet title="श्री गुरूगजाननलीला ग्रंथ - अध्याय १७ वा" />
        <HeaderGeneric title="श्री गुरूगजाननलीला ग्रंथ" adhyaay='अध्याय १७ वा' num='17'/>
        <div id="main">
          <section id="content" className="main">
            <p>
            श्री गणेशाय नम: । सविता सूर्यनारायणा । करू किती मी विनवण्या ? । येण्या माझ्या स्थानाला ॥ १ ॥
नाश करतो अज्ञान तिमिरा । तूच मांडला ज्ञान पसारा । अज्ञानास न मिळे थारा । तुला विनविता अंगातून धारा ॥ २ ॥
घालतो द्वादश नमस्कार । करावा त्यांचा स्विकार । पत्नीचाही जीव तुझ्यावर । अर्पिते भक्ती निष्ठेचा हार ॥ ३ ॥
जाण मनोरथ आमचे । काय हेतू उभयतांचे । स्वतः भक्तीत रमायचे । दुसर्‍यांस भक्तीत रमवायचे ॥ ४ ॥
आम्ही वेगळे देहाने । परी एकच विचाराने । अज्ञानावर पडावीत किरणे । ज्ञानी होण्यास न राहे उणे ॥ ५ ॥
सर्वज्ञ सूर्य नारायणा । धावून यावे या स्थाना । तुझ्या प्रकाशाविणा । अशक्यच जगण्या ॥ ६ ॥
सूर्यनारायणा ! तूच जीव रक्षितो । तूच आम्हां रक्षितो । तूच दीनोद्धार करतो । गणामहाराज जाणतो ॥ ७ ॥
तुझ्या ठराविकपणास । तू येतोस उदयास । असू दे सहाय्य लेखनास । तुझा प्रकाश उपयुक्त लेखनास ॥ ८ ॥
सूर्यनारायण संबोधिता गुरूस । त्याच्या कृपेने अज्ञान नाश । भक्त विनविती गुरूस । येण्या आपल्या घरास ॥ ९ ॥
असेच वाटले एका भक्तास । मलकापूरच्या विष्णुसास । महाराज यावे आपल्या घरास । कृपाशिर्वाद असावेत सर्वांस ॥ १० ॥
हा विष्णुसा खटावच्या गिरणीत । नोकरीस होता त्यात । समर्थ आले असता गिरणीत । विचार आला त्याच्या मनात ॥ ११ ॥
अकोल्याच्या गिरणीत । आणले स्वामी समर्थ । भूमी पावन करण्यात । मनोरथ पूर्ण करण्यात ॥ १२ ॥
मनोरथांस घोडे समर्थांचे । समर्थांविणा पुढे न जाणे रथाचे । ते मध्येच रूतून बसायचे । काही हाती न यावयाचे ॥ १३ ॥
भक्तांच्या मनोरथास । समर्थ कृपेचे घोडे असल्यास । मदतरूप पुढे जाण्यास । कुणी न अडविणार त्यास ॥ १४ ॥
हे न भक्त विसरणार । तेणे समर्थां विनविणार । आपल्या घरी नेणार । हेतू साध्य करणार ॥ १५ ॥
गुरूकृपेतच सारे मिळते । हे अनुभवाने पटते । जे जे मनास पटते । त्यानुसार केले जाते ॥ १६ ॥
मलकापूरच्या विष्णुसास । गुरूंचे महत्व पटले त्यास । जेणे विनविले समर्थांस । म्हणे यावे आपल्या घरास ॥ १७ ॥
समर्थ वदले त्यास । आग्रह करू नकोस आम्हांस । पुन्हा कधी येऊ घरास । जाणतो तुझ्या भावनांस ॥ १८ ॥
समर्थांस मलकापूरी न्यायला । लावला पाहिजे वशीला । भास्कर योग्य त्याला । विचार मनी आला ॥ १९ ॥
खुलवले त्याने भास्करास । जवळचा तू महाराजांस । तूच सांगता पटेल त्यांस । तेणे समर्थ येतील घरास ॥ २० ॥
भास्करास पटले । महाराजांस विनविले । आमंत्रण विष्णुसाचे आले । मलकापुरी जाणे नक्की केले ॥ २१ ॥
महाराज वदले भास्करास । नको बळी पडू आग्रहास । पुढे जाऊ मलकापुरास । सांगावे तैसे विष्णुसास ॥ २२ ॥
नको घेऊस आमंत्रण । फजीतीस उगाच कारण । समर्थे पुढचे जाणून । भास्करा सांगितले बजावून ॥ २३ ॥
भास्कर वदे समर्थांस । जाऊ विष्णुसाच्या घरास । बोलावले इतुके पूजनास । नाही का हो म्हणावे त्यास ? ॥ २४ ॥
भास्करे समर्थां सांगितले । मलकापूरचे नक्की केले । त्यास तैसे मी सांगितले । आता जाणे भाग पडले ॥ २५ ॥
मलकापुरास जावया निघाले । इच्छेविरूद्ध झाले । गाडीने जाण्याचे नक्की केले । स्टेशनावर दोघे आले ॥ २६ ।
गाडी आली क्षणात । गर्दी झाली क्षणात । काही उतरण्यात, चढण्यात । जो तो आपापल्या तंद्रीत, विचारात ॥ २७ ॥
स्टेशन मास्तरास विनविले । डबा मोकळा करण्यास सांगितले । समर्थांस बसण्यास विनविले । व्याधी उपाधीस केले मोकळे ॥ २८ ॥
भित्या पाठी ब्रह्मराक्षस । आमंत्रण दिले व्याधी उपाधीस । तोच प्रसंग अनुभवास । आला येथे भास्करास ॥ २९ ॥
मोकळ्या झालेल्या डब्यात । समर्थांनी बसावे त्यात । ह्या हेतूने तो वदे समर्थांस । “बाबा! बसावे डब्यात” ॥ ३० ॥
महाराज काही न बोलले । प्लॅटफॉर्म वर तसेच बसले । मंत्रजपात तल्लीन झालेले । एका वेगळ्याच आनंदात रमलेले ॥ ३१ ॥
गाडी जास्त वेळ थांबवेना । गाडीस शिटी दिल्यावाचून चालेना । शिटी काही दिली जाईना । गाडी काही सुटेना ॥ ३२ ॥
भास्कर जरा गोंधळला । थोडा बाजूस सरला । समर्थे संधीचा फायदा घेतला । भास्करास चकवला ॥ ३३ ॥
समर्थांच्या मनात विचार आला । त्यांनी मार्ग अनुसरला । मोकळा डबा सोडला । स्त्रियांचा डबा धरला ॥ ३४ ॥
एक योगी स्त्रियांची अब्रू रक्षितो । कुविचार त्याचे मनात न येतो । तो हितरक्षक असतो । तेणेच ऐसा विचार मनात येतो ॥ ३५ ॥
डब्यातल्या बायका घाबरल्या । एकमेकीकडे बघू लागल्या । योग्यास नंगा पिसा समजल्या । त्यास हाकलवू लागल्या ॥ ३६ ॥
तक्रार केली पोलिसांस । धावून यावे लागले पोलिसांस । समजावले समर्थांस । सांगितले खाली उतरण्यास ॥ ३७ ॥
स्टेशनमास्तरही येता झाला । वृत्तांत सर्व जाणला । सर्वांना समजावू लागला । ह्याचा न त्रास तुम्हाला ॥ ३८ ॥
ह्यांना राहू द्यावे डब्यात । बायका काही ऐकेनात । म्हणती नंगा पिसा डब्यात । पोलिसही ऐकेनात ॥ ३९ ॥
पुष्कळ समजावले समर्थांना । काही उपयोग होईना । स्टेशन मास्तरने समर्थांना । केली विनम्र प्रार्थना ॥ ४० ॥
कृपया खाली उतरावे । बायकांच्या विनंतीस मान द्यावे । कायद्यानेच वागावे । कायद्यापुढे न चालावे ॥ ४१ ॥
महाराज उतरले खालती । ह्या त्यांच्या गुन्ह्यावरती । खटला भरला त्यांचेवरती । कायद्याप्रमाणे वागण्यासाठी ॥ ४२ ॥
भास्कराचा थरकाप झाला । समर्थांच्या इच्छेविरुद्ध जाण्याला । विपरित प्रसंग भोगण्याला । उगाच स्विकारले आमंत्रणाला ॥ ४३ ॥
चौकशीस बापूसाहेब जठार । निःपक्षपणे न्याय देणार । संपूर्ण चौकशी करणार । न्याय देण्यात हुशार फार ॥ ४४ ॥
तारीख खटल्याची आली । गर्दी डाकबंगल्याजवळ झाली । फिर्याद समर्थांवर झाली । सुनवणी ऐकण्यास जमली ॥ ४५ ॥
व्यंकटराव देसाई कामानिमित्त आलेले । ते गर्दी पहाते झाले । जठारास विचारते झाले । वृत्त सर्व त्यांस कळले ॥ ४६ ॥
जठारे सांगितले देसायास । बोलवावे समर्थांस । धाडले बोलावणे समर्थांस । कुणी सुचविले नेसवा धोतरास ॥ ४७ ॥
नागवेपणाचा झाला गुन्हा । तो न व्हावा पुन्हा पुन्हा । नेसवा धोतर महाराजांना । नंतरच न्यावे त्यांना ॥ ४८ ॥
हा सल्ला पटल्यावर । समर्थांस नेसवले धोतर । वाटेत पुढे गेल्यावर । समर्थे सोडले धोतर ॥ ४९ ॥
नागवेच गेले चौकशीस । नेले बरोबर भास्करास । जठारांनी पुसले समर्थांस । का हो नागवे फिरता रस्त्यांत ? ॥ ५० ॥
महाराज त्यावर हसले । तुझी डबी काढ झाले । हातात तंबाखू चोळू लागले । जठार क्षणात विरघळले ॥ ५१ ॥
जठार न्याय देते झाले । जठार सर्वांस म्हणाले । बोलावण्या शिपाया पाठवले । तो वेगळेच अनुभवास आले ॥ ५२ ॥
शिपायाच्या हातास असे धरले । रक्तप्रवाह बंद झाले । धोतर नेसण्यास लावले । तेही वाटेत सोडले ॥ ५३ ॥
ते न कुणा घाबरले । न कुणाची अब्रू घेते झाले । न कुणाच्या वाटेला गेले । स्वतःच्या तंद्रीत राहिले ॥ ५४ ॥
मुळचाच विदेही विकार नसलेला । हा स्वतःचा न राहिला । दुसर्‍याने संभाळावे त्याला । जेणे टळेल उपाधीला ॥ ५५ ॥
जो असे ह्यांच्या बरोबर । तोच काळजी घेणार । जो न त्यांस संभाळणार । तोच गुन्हेगार ठरणार ॥ ५६ ॥
ह्यांची जवाबदारी संभाळणार्‍यावर । नच त्यांच्या स्वतःवर । ते न गुन्हेगार ठरणार । सांभाळणाराच गुन्हेगार ठरणार ॥ ५७ ॥
अग्नी शोभतो अग्निहोत्रात । ते सांडण्या विपरित । अग्नी न दोषी ठरत । सांभाळणाराच असावा जागृत ॥ ५८ ॥
येथे सांभाळणारा भास्कर । पाच रूपये दंड भरावा सत्वर । चूक जाणता झाला भास्कर । म्हणे पुन्हा ऐशी चूक न होणार ॥ ५९ ॥
समर्थे आधीच बजावले त्यास । नको मलकापूर प्रवास । कारण होशील फजितीस । तेच आले अनुभवास ॥ ६० ॥
गुरू आज्ञा उल्लंघनात । दुःख यातना होतात । गुरू आज्ञा पालनात । प्रारब्ध भोग सुसह्य होतात ॥ ६१ ॥
महाराजांचे सहवासात । जे जे प्रसंग घडतात । ते उद्बोधक ठरतात । मार्गदर्शक ठरतात ॥ ६२ ॥
समर्थांचे सत्संगती अनेक । त्यातला अकोल्यातील एक । समर्थांचे भक्त अनेक । त्यातला अकोल्याचा बापूराव एक ॥ ६३ ॥
बापूरावाने समर्थांस । विनविले घरी येण्यास । यावे तुम्ही अकोल्यास । कृपाशिष देण्यास ॥ ६४ ॥
होकार दिला बापूरावास । कबूल केले येणे अकोल्यास । रेल्वेने जाण्यास । हिम्मत न होई भास्करास ॥ ६५ ॥
भास्करे विचारले समर्थांस । अकोल्यास जाण्यास । करू का बैलगाडीस ? । वा दुसर्‍या साधनास ? ॥ ६६ ॥
बैलगाडीने निघाले अकोल्याला । बापूरावाचे सदनाला । घाबरला भास्कर रेल्वेला । त्या कोर्टकचेरीला ॥ ६७ ॥
तत्पूर्वी महताबशा बापूरावाला वदला । बापूराव! अवलीया येता तुझ्या घराला । आमंत्रण धाड मला । येईन त्याला भेटायला ॥ ६८ ॥
बापूराव स्मरता झाला । माणूस कुरुमला पाठवला । अंतर्ज्ञाने कळले महताबशाला । तेणे गाडीतून निघाला अकोल्याला ॥ ६९ ॥
तो अर्ध्या वाटेत आला । एक माणूस भेटला त्याला । पत्ता महताबशाचा विचारला । सांगे आलो घ्यायला त्याला ॥ ७० ॥
ऐकता “तोच मी महताबशा” वदला । “जाऊ नको तू कुरूमला । बापूरावाचे आमंत्रण कळले मला । जेणे निघालो अकोल्याला” ॥ ७१ ॥
महताबशाने सांगितले । त्या माणसास पटले । आपल्या गाडीत बसवले । बापूरावाच्या घरी आणले ॥ ७२ ॥
दुसरे दिवशी महाराज आले । महताबशास पहाते झाले । त्याचे केस पकडले । त्यास भानावर आणले ॥ ७३ ॥
साथीदारास दृष्य पहावेना । ह्यातील मर्म कळेना । बोलती समर्थांना । कोणता केला गुन्हा ? ॥ ७४ ॥
समर्थ वदले “अरे महताबा! । सोड येथला ताबा । तुमचे प्रस्थ माजले बाबा । लोक येथले त्रस्त बाबा ॥ ७५ ॥
तू करावास उपदेश । नका पीडा देऊ दुसर्‍यांस । अकारण पीडा दिल्यास । अल्ला न सोडणार तुम्हास” ॥ ७६ ॥
तत्व कळले महताबशास । नको कारण तमाशास । म्हणाला साथीदारांस । नका पीडा देऊ कुणास ॥ ७७ ॥
अवलीयाची आज्ञा प्रमाण मानतो । कुरुमची मशीद तशीच ठेवतो । पूर्ण करण्यास दुसर्‍यास सांगतो ॥ ७८ ॥
तुम्ही धरावे ह्यांचे चरण । करावे आज्ञा पालन । चौघांना कुरूमला दिले पाठवून । मशीदीचे काम करण्या पूर्ण ॥ ७९ ॥
येथे बच्चूलाल आला दर्शनाला । आमंत्रण करायला । दुसरे दिवशी टांगा केला । बच्चूलालच्या घराला ॥ ८० ॥
टांगा आला बच्चूलाल सदना । परी समर्थ काही उतरेना । प्रयत्न केले नाना । परी यश कुणा येईना ॥ ८१ ॥
एका धूर्ताने सांगितले । महताबशास वगळले । तेणे न खाली उतरले । तैसेच बापूरावाकडे परतले ॥ ८२ ॥
टांग्यात बसवा दोघांना । आनंद होईल महाराजांना । म्हणणे पटले सर्वांना । भोजना बोलविले दोघांना ॥ ८३ ॥
दोघांना टांग्यात बसविले । गावात मिरविले । महाराज खूष झाले । बच्चूलालास हायसे वाटले ॥ ८४ ॥
महताबशास थेटरात उतरवले । समर्थांस राम मंदिरात उतरवले । समर्थ महताबशास भेटले । उदात्त भोळे भाव व्यक्त केले ॥ ८५ ॥
समर्थांची शिकवण । दिसे त्यांच्या कृतीतुन । महताबशास केले ताडण । परी न जेवले त्याच्यावाचून ॥ ८६ ॥
दोघे बरोबर जेवले । महताबे शेख कडूस बोलवले । पंजाबचे तिकिट काढण्या सांगितले । सांगे येथले कार्य संपले ॥ ८७ ॥
शेख कडू म्हणे त्यास । “नका अर्धवट टाकू मशिदीस । तुमच्यावीण न पूर्णत्वास । नंतर जावे पंजाबास” ॥ ८८ ॥
महताब बोलले त्यावर । “मी कोण मशीद पूर्ण करणार? । आपापल्या कल्पनांवर । निराकारच करतो साकार “॥ ८९ ॥
दगड, माती, चुना, वीटा । हीच साधने दोन्हिंत असता । आकारने वेगळे होता । मंदिर मशिद म्हणता ॥ ९० ॥
नको महत्व देखाव्याला । महत्व द्यावे आतल्याला । नको महत्व रूपाला । महत्व द्यावे आत्म्याला ॥ ९१ ॥
आत्म्याचे चिंतन हाच सार । तोच अमर रहाणार । देह चिंतन सदा असार । तेणे भूवर होतो भार ॥ ९२ ॥
भेदभावास विसरावे । ते न कधी आचरावे । तेच घातक समजावे । अभेद वृत्तीने जगावे ॥ ९३ ॥
जरी जाती पाती जन्माने । एक व्हा कर्माने, कृतीने । नांदता ऐक्य भावाने । नांदाल सौख्याने ॥ ९४ ॥
शेख कडू तत्व समजला । पंजाबच्या तिकिटाला । तो काढता झाला । समर्थांचा अधिकार कळला ॥ ९५ ॥
इकडे बापूरावाच्या पत्नीस । भानामतीचा त्रास । बिब्ब्यांच्या फुल्या उठाव्या अंगास । तशाच त्या सर्व कपड्यांस ॥ ९६ ॥
दांडीवरचे वस्त्र जळावे । कधी अंगाची लाही लाही व्हावे । अन्न पाणी न गोड लागावे । मन कशातही न रमावे ॥ ९७ ॥
ती कंटाळली त्रासाला । ती कंटाळली जीवाला । घरचेही कंटाळले व्याधीला । भानामतीच्या उपाधीला ॥ ९८ ॥
डॉक्टर वैद्य सर्व झाले । औषधे करून थकले । मांत्रिकासही बोलाविले । गंडे, दोरे, ताईत केले ॥ ९९ ॥
नाना तर्‍हेचे इलाज केले । परी निरूपयोगी ठरले । द्रव्य अतोनात खर्चिले । त्यासही अपयश आले ॥ १०० ॥
सरते शेवटी वदे समर्थांस । पत्नीस भानामतीचा त्रास । तेणे कंटाळली जीवास । नाना केले उपायास ॥ १०१ ॥
एकही उपाय न उपयोगास । तुम्हीच पहावे पत्नीस । मुक्त करा भानामतीस । हीच प्रार्थना तुम्हास ॥ १०२ ॥
बापूरावाचे हृदय व्यथीत । समर्थे जाणले क्षणात । वदले आराम पडेल दुखण्यास । होऊ नकोस भयभीत ॥ १०३ ॥
पत्नीकडे बघितले । तीस बोलते झाले । पोरी त्रास कसले ? । आता दुखणे पळाले ॥ १०४ ॥
पुढे एकदा समर्थ । आले अकोट गावाप्रत । मित्र नरसिंग राहे त्यात । उत्सुकता त्यास भेटण्यास ॥ १०५ ॥
त्याच्या घरी आल्यावर । रसभरीत गप्पा झाल्यावर । स्नानाचे मनात आल्यावर । समर्थ गेले विहिरीवर ॥ १०६ ॥
विहिरीच्या काठावर बसले । विहिरीत पाय सोडले । आत डोकावू लागले । नरसिंगही विचारात पडले ॥ १०७ ॥
असामान्यांचे सारे असामान्य । जेणे मिळते प्राधान्य । हे नसते पिसेपण । ह्यातूनच होते शिकवण ॥ १०८ ॥
नरसिंग तेथे आले । दृष्य बघते झाले । लोक सभोवताली जमले । चर्चेत ते गुंतले ॥ १०९ ॥
आपापल्या बुद्धीनुसार बोलले । परी येथे वेगळेच घडले । समर्थ पिसा वाटलेले । परी तेच समर्थ ठरले ॥ ११० ।
समर्थ वदती नरसिंगास । पहातो विहिरीत गंगा गोदेस । त्यांची कृपा असल्यास । न्हाऊ घालतील आम्हांस ॥ १११ ॥
तू रोज स्नान करतो । आपली काया धन्य करतो । मी त्यांचा उगम बघतो । तेणे समाधान मानतो ॥ ११२ ॥
समर्थे एक चित्ते डोकावले । मंत्रजपात रंगलेले । तेथेच बसून राहिले । सर्वांस आश्चर्य वाटले ॥ ११३ ॥
दृष्टी विहिरीकडे स्थीर केली । विहिर क्षणात भरली । जणू एक एक नदी आली । विहिरीत मिसळली ॥ ११४ ॥
स्मरणात देखिल आमंत्रण । एकचित्ते करता स्मरण । जेणे येणे आपणहून । जेणे पटेल खूण ॥ ११५ ॥
ऐसे हाक मारणे व्हावे । स्वारीने हजर रहावे । ऐसे त्यास आळवावे । जेणे चित्त भुलावे ॥ ११६ ॥
जितकी जोरदार ईच्छाशक्ती । तितकीच जोरदार होते कृती । त्यानेच साधते प्रगती । हेच दिसते जगती ॥ ११७ ॥
जितकी ईच्छाशक्ती प्रबळ । तितका “तो” येतो जवळ । ”तो” येता जवळ । संकटे काढती पळ ॥ ११८ ॥
जो नामात रहातो । तो “मी” स विसरतो । जो “मी” स घालवतो । तो “तो” बोलवतो ॥ ११९ ॥
गणामहाराज करे प्रार्थना । हे सूर्य नारायणा । दूर करावे अज्ञाना । करावे ज्ञानी भक्तांना ॥ १२० ॥
गणामहाराज सांगे भक्तांस । जाणावे पूर्ण एकास । तोच धाडतो अनेकांस । येथेच सतरावा अध्याय जातो पूर्णत्वास ॥ १२१ ॥

 
॥ शुभं भवतु ॥
॥ श्री गणपती सरस्वतीर्पणमस्तु ॥
॥ इति श्रीगुरूगजाननलीला ग्रंथस्य सप्तदशोऽध्यायः संपूर्णः ॥
</p>
          </section>
        </div>
      </Layout>
    )
  }
}

export default Generic
